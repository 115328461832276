export async function fileToBase64 (file: File): Promise<string> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = (): any => {
      resolve(btoa(String(reader.result)));
    };
    reader.readAsBinaryString(file);
  });
}

export function base64ToFile (base64: string, filename: string, filetype: string): File {
  const binaryData = atob(base64);

  const arrayBuffer = new ArrayBuffer(binaryData.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < binaryData.length; i++) {
    uint8Array[i] = binaryData.charCodeAt(i);
  }

  return new File([arrayBuffer], filename, { type: filetype });
}

export function base64ToArrayBuffer (base64: string): any {
  const binaryString = window.atob(base64);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);

  for (let i = 0; i < binaryLen; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return bytes.buffer;
}
