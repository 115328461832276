<template>
  <v-container fluid>
    <v-card flat class='wd-100'>
      <v-card-title class='px-4 py-0'>
        <v-row no-gutters class='pa-2 mb-3'>
          <div class='text-h5' :style='{ color: $vuetify.theme.defaults.light.primary }'>
            {{ $t('RECORD_VALIDATION') }}
          </div>
        </v-row>
        <v-spacer />
        <v-btn icon class='ml-3 mt-3' @click='getValidationList'>
          <v-icon color='secondary'>mdi-refresh</v-icon>
        </v-btn>
        <v-col v-if='isSuperAdmin' cols='2' class='mt-5 py-0'>
          <v-autocomplete
            v-model='customerSelect'
            :items='customerList'
            item-text='name'
            return-object
            label='Cliente'
            placeholder='Selecione um cliente'
            @change='getValidationList'
          />
        </v-col>
        <v-col cols='2' class='py-0'>
          <v-select
            v-model='validationStatusSelect'
            class='mt-6'
            label='Estado de validação'
            :items='["Todos", "Somente pendentes", "Somente rejeitados", "Somente aprovados"]'
          />
        </v-col>
        <v-col cols='4' class='mr-4'>
          <v-text-field
            v-model='search'
            append-icon='mdi-magnify'
            :label='$t("SEARCH")'
            single-line
            hide-details
            clearable
          />
        </v-col>
      </v-card-title>
      <v-data-table
        item-key='id'
        :headers='validationListHeaders'
        :search='search'
        :items='compValidationList'
        :items-per-page='8'
        single-expand
        show-expand
        max-height='36vh'
        fixed-header
        :expanded.sync='expandedList'
        @click:row='onDtRowClick'
      >
        <template #item.validation_status='{ item }'>
          <v-chip
            small
            :color='getValidationStatus(item.validation_status_id)'
            dark
          >
            {{ item.validation_status || "Pendente" }}
          </v-chip>
        </template>
        <template #expanded-item='{ headers, item }'>
          <td :colspan='headers.length' style='position: relative'>
            <RecordSearch
              :key='item.cpf'
              :customer-id='getUserCustomerId'
              :validation-cpf='item.cpf'
              validation
              @reload-data='onReloadData'
            />
          </td>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import Swal from 'sweetalert2';
  import { mapGetters } from 'vuex';

  export default {
    name: 'RecordValidation',
    components: {
      RecordSearch: () => import('../RecordSearch/RecordSearch'),
    },
    data: function () {
      return {
        customerSelect: '',
        customerList: [],
        search: '',
        validationListHeaders: [
          { text: 'Nome', value: 'full_name' },
          { text: 'CPF', value: 'cpf' },
          {
            text: 'Estado de validação',
            value: 'validation_status',
            width: '15%',
            align: 'center',
          },
          { text: '', value: 'data-table-expand', width: '10' },
        ],
        validationList: [],
        expandedList: [],
        validationStatusSelect: 'Todos',
        validationsStatuses: {
          PENDING: 1,
          ACCEPTED: 2,
          REJECTED: 3,
        },
      };
    },
    computed: {
      ...mapGetters('auth', [
        'getUser',
      ]),
      isSuperAdmin: function () {
        return this.getUser?.account?.accountRoleId === 1;
      },
      getUserCustomerId: function () {
        if (this.isSuperAdmin) {
          return this.customerSelect.id;
        }
        return this.getUser?.account?.customerId;
      },
      compValidationList: function () {
        switch (this.validationStatusSelect) {
          case 'Somente pendentes': return this.validationList.filter((vl) => (!vl.validation_status_id || vl.validation_status_id === this.validationsStatuses.PENDING));
          case 'Somente rejeitados': return this.validationList.filter((vl) => vl.validation_status_id === this.validationsStatuses.REJECTED);
          case 'Somente aprovados': return this.validationList.filter((vl) => vl.validation_status_id === this.validationsStatuses.ACCEPTED);
          default: return this.validationList;
        }
      },
    },
    watch: {
      getUserCustomerId: function (newValue) {
        if (newValue) {
          this.getValidationList();
        }
      },
    },
    mounted: function () {
      if (this.getUserCustomerId) {
        this.getValidationList();
      } else {
        this.getCustomers();
      }
    },
    methods: {
      getCustomers: async function () {
        try {
          this.$store.dispatch('loading/toggleLoading');
          const result = await axios({
            url: '/customers',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
          });
          this.customerList = result.data.customers;
          this.$store.dispatch('loading/toggleLoading');
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
          this.$store.dispatch('loading/toggleLoading');
        }
      },
      getValidationList: async function () {
        if (!this.getUserCustomerId) {
          Swal.fire({
            icon: 'warning',
            text: 'É necessário informar um cliente',
            confirmButtonText: 'OK',
            confirmButtonColor: this.$vuetify.theme.defaults.light.secondary,
          });
          return;
        }
        try {
          this.$store.dispatch('loading/toggleLoading');
          const result = await axios({
            url: `/customer/pending-validation/${this.getUserCustomerId}`, // eslint-disable-line
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
          });
          this.validationList = result.data.list;
          this.$store.dispatch('loading/toggleLoading');
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
          this.$store.dispatch('loading/toggleLoading');
        }
      },
      getValidationStatus: function (validationStatusId) {
        return validationStatusId === this.validationsStatuses.ACCEPTED ? 'success' // eslint-disable-line
        : validationStatusId === this.validationsStatuses.REJECTED ? 'error' // eslint-disable-line
        : this.$vuetify.theme.defaults.light.tertiary; // eslint-disable-line
      },
      onDtRowClick: function (item) {
        if (this.expandedList.find((l) => l.cpf === item.cpf)) {
          this.expandedList = [];
        } else {
          this.expandedList = [item];
        }
      },
      onReloadData: function (item) {
        this.onDtRowClick(item);
        this.getValidationList();
      },
    },
  };
</script>
