<template>
  <v-container fluid>
    <v-stepper v-model='step'>
      <v-stepper-header>
        <v-stepper-step
          :complete='step > 1'
          step='1'
        >
          Seleção do cliente
        </v-stepper-step>

        <v-divider />

        <v-stepper-step
          :complete='step > 2'
          step='2'
        >
          Download do template
        </v-stepper-step>

        <v-divider />

        <v-stepper-step
          :complete='step > 2'
          step='3'
        >
          Upload template
        </v-stepper-step>

        <v-divider />

        <v-stepper-step
          :complete='step > 4'
          step='4'
        >
          Confirmação
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step='1'>
          <v-col cols='3' class='ml-2 mt-2'>
            <v-autocomplete
              v-model='customerSelect'
              :items='customerList'
              required
              placeholder='Selecione um cliente'
              color='secondary'
              @next='nextStep'
              @change='recordList = [];'
            />
            <v-btn
              color='secondary'
              :disabled='!customerSelect'
              @click='nextStep'
            >
              Avançar
            </v-btn>
          </v-col>
        </v-stepper-content>
        <v-stepper-content step='2'>
          <v-row>
            <v-col cols='11' class='ml-16 mt-2'>
              <v-row class='py-10'>
                <p id='template-text' class='text-justify text--secondary'>
                  A importação será feita através de um arquivo no formato zip que deverá conter as pastas com as biometrias de cada cliente e a lista.csv. O arquivo deverá conter os seguintes itens:<br><br>
                  1 - Uma lista com as informações  de todos os clientes que serão atualizados no formato CSV. <br>
                  Faça o download do template e preencha com todas as informações especificadas. As informações dos cadastros que serão atualizadas correspondem às colunas presentes no documento template conforme a imagem abaixo.
                </p>
                <v-btn
                  v-if='step==2'
                  href='/utils/importer/lista.csv'
                  download='download'
                  class='ml-3'
                  color='success'
                  @next='nextStep'
                  @back='backStep'
                >
                  Download do template CSV
                </v-btn>
              </v-row>
              <v-img
                position='center center'
                src='/utils/importer/list_template.png'
              />
              <v-row class='py-10'>
                <p class='text-justify text--secondary'>
                  2 - Uma pasta para cada cliente que deverá receber como nome o id do cliente que representa. Os itens citados devem estar organizados conforme o exemplo abaixo:<br>
                </p>
              </v-row>
              <v-img
                max-height='350'
                max-width='150'
                position='center center'
                src='/utils/importer/folders_template.png'
              />
              <v-row class='py-10'>
                <p class='text-justify text--secondary'>
                  3 - Dentro da pasta de cada cliente deverão estar suas biometrias (foto de rosto e imagem da assinatura do cliente), sendo que cada um desses arquivos deverão possuir o mesmo nome que estiver no arquivo lista.csv.
                </p>
              </v-row>
              <v-img
                max-height='200'
                max-width='450'
                position='center center'
                src='/utils/importer/customer_folder_template.png'
              />
              <v-row class='py-15'>
                <v-btn
                  color='secondary'
                  class='ml-3'
                  @click='backStep'
                >
                  Voltar
                </v-btn>
                <v-btn
                  color='secondary'
                  class='ml-3'
                  @click='nextStep'
                >
                  Avançar
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-stepper-content>
        <v-stepper-content step='3'>
          <v-row>
            <v-col cols='3' class='py-5'>
              <v-form enctype='multipart/form-data'>
                <v-file-input
                  v-model='file'
                  show-size
                  label='Importar Arquivo Zip'
                  name='file'
                  accept='application/x-zip-compressed,image/*'
                />
              </v-form>
              <v-row class='ml-2 py-5'>
                <v-btn
                  color='secondary'
                  @click='backStep'
                >
                  Voltar
                </v-btn>
              </v-row>
            </v-col>
            <v-col cols='1' class='ml-2 mt-2 py-5'>
              <v-btn
                color='success'
                :disabled='!file'
                @click='uploadFile'
              >
                Carregar
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>
        <v-stepper-content step='4'>
          <Printed
            v-if='step == 4'
            :selected='selectedImport'
            @next='nextStep'
            @back='backStep'
            @selected='getSelectedImport'
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <PasswordRequired v-if='showConfirmPassword' @password-success='registerStockShipment' @password-cancel='closeModalPassword' />
  </v-container>
</template>

<script>
  import axios from 'axios';
  import Swal from 'sweetalert2';
  // eslint-disable-next-line
  var FormData = require('form-data');

  export default {
    name: 'Importer',
    data: function () {
      return {
        step: 1,
        showConfirmPassword: false,
        file: null,
        linkRenach: false,
        fieldToSave: '',
        search: '',
        selectedImport: [],
        customers: null,
        customerList: [],
        customerSelect: '',
        recordList: [],
      };
    },
    mounted: function () {
      this.getCustomers();
    },
    methods: {
      nextStep: function () {
        this.step++;
      },
      backStep: function () {
        this.step--;
      },
      getCustomers: async function () {
        try {
          this.$store.dispatch('loading/toggleLoading');
          const result = await axios({
            url: '/customers',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
          });

          this.customers = result.data.customers;
          this.$store.dispatch('loading/toggleLoading');

          if (this.customers) {
            this.customers.forEach((customer) => {
              const customerObj = {
                text: customer.name,
                value: customer.initials,
              };

              this.customerList.push(customerObj);
            });
          }
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
          this.$store.dispatch('loading/toggleLoading');
        }
      },
      upload: async function () {
        const uploadFile = new FormData();
        uploadFile.append('file', this.file, this.customerSelect);
        this.$store.dispatch('loading/toggleLoading');

        await axios({
          url: '/customer/upload',
          baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
          method: 'POST',
          withCredentials: true,
          data: uploadFile,
          params: {
            customer: this.customerSelect,
          },
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(() => {
          this.$store.dispatch('loading/toggleLoading');
          Swal.fire({
            icon: 'success',
            title: this.$t('SUCCESS'),
            text: 'Upload realizado com sucesso',
          });
        }).catch((e) => {
          this.$store.dispatch('loading/toggleLoading');
          Swal.fire({
            icon: 'error',
            title: this.$t('WARNING'),
            text: 'Ocorreram problemas ao realizar o upload. Verifique se o formato da lista.csv está conforme o template',
          });
          console.log('error:', e.response); // eslint-disable-line
        });
      },
      uploadFile: function () {
        Swal.fire({
          icon: 'warning',
          title: 'Confirmar importação',
          text: this.$t('CONFIRM_IMPORT', { customerName: this.customerSelect }),
          confirmButtonText: 'Sim',
          showCancelButton: true,
          cancelButtonText: this.$t('CANCEL'),
        }).then((result) => {
          if (result.isConfirmed) {
            this.upload();
          }
        });
      },
      getSelectedImport: function (value) {
        this.selectedImport = value;
      },
    },
  };
</script>

<style scoped lang="scss">
.form-container {
  padding: 30px;
}
#template-text {
  line-height: 2;
}
</style>
