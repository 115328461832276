<template>
  <v-container fluid>
    <v-card v-if='!validation' card='mt-4 mb-3'>
      <v-row no-gutters class='pa-5'>
        <div class='text-h5'>
          {{ $t('RECORD_SEARCH') }}
        </div>
      </v-row>
      <v-row no-gutters>
        <v-col cols='3' class='pa-5'>
          <v-autocomplete
            v-model='customerSelect'
            :items='customerList'
            item-text='name'
            color='secondary'
            return-object
            label='Cliente'
            placeholder='Selecione um cliente'
          />
        </v-col>
        <v-col cols='2' class='pa-5'>
          <v-text-field v-model='cpf' :label='$t("CPF")' maxlength='11' />
        </v-col>
        <v-col cols='3' class='mt-8 ml-5'>
          <v-btn
            color='secondary'
            @click='search'
          >
            {{ $t("SEARCH") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

    <component :is='validation ? "div" : "vCard"' v-if='record' class='mt-2'>
      <v-card-text>
        <v-alert :color='record.openbioValidation.valid ? "success": "warning"' width='70%' dark>
          <p class='mb-0'>
            <v-icon large> {{ record.openbioValidation.valid ? 'mdi-check-circle-outline' : 'mdi-alert-outline' }} </v-icon>
            {{ record.openbioValidation.valid ? 'Os dados estão de acordo com as nossas validações para emissão de documento' : 'Encontramos alguns erros que podem impedir a emissão do documento' }}
          </p>

          <ul v-if='!record.openbioValidation.valid'>
            <li v-for='item in record.openbioValidation.errors' :key='item'>{{ item }}</li>
          </ul>
        </v-alert>
        <v-container>
          <v-row>
            <v-col cols='6' style='position: relative'>
              <TitleValue custom-label='Foto' :value='record.foto' :options='{ type: "image", width: 200 }' />
              <v-btn
                v-if='editing'
                x-small
                class='ml-9 img-upload-btn'
                fab
                style='bottom: 16px; left: 141px; position: absolute;'
                @click='openFileBrowser("photo")'
              >
                <v-icon> mdi-upload </v-icon>
              </v-btn>
            </v-col>
            <v-col v-if='!validation && !editing' cols='6'>
              <TitleValue custom-label='QRCode' :value='record.qrCode' :options='{ type: "image", width: 200 }' />
            </v-col>
            <v-col style='position: absolute; right: 40px; top: 40px;'>
              <v-row>
                <v-spacer />

                <v-btn v-if='editing' class='animated-btn mr-3' outlined color='#9a4fff' @click='cancelEdit'>
                  <v-icon small> mdi-undo </v-icon>
                  <span> Cancelar edição </span>
                </v-btn>
                <v-btn class='animated-btn mr-3' :dark='!editing' :color='editing ? "success" : "#9a4fff"' @click='editing ? confirmationPopup() : editRecord()'>
                  <v-icon small> {{ editing ? "mdi-check" : "mdi-pencil" }} </v-icon>
                  <span> {{ editing ? "Finalizar edição" : "Editar" }} </span>
                </v-btn>
                <v-btn v-if='canChangeStatusBack()' class='animated-btn mr-3' :dark='!editing' color='warning' @click='validateRecord(validationsStatuses.PENDING)'>
                  <v-icon small>mdi-backup-restore</v-icon>
                  <span> Voltar para validação </span>
                </v-btn>
                <v-btn v-if='!editing && validation' class='animated-btn mr-3' color='error' @click='validateRecord(validationsStatuses.REJECTED)'>
                  <v-icon small> mdi-close </v-icon>
                  <span> Rejeitar </span>
                </v-btn>
                <v-btn v-if='!editing && validation' class='animated-btn' color='success' @click='validateRecord(validationsStatuses.ACCEPTED)'>
                  <v-icon small> mdi-check </v-icon>
                  <span> Aprovar </span>
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols='6'>
              <component :is='editing ? "v-text-field" : "TitleValue"' v-model='record.name' label='Nome' custom-label='Nome' :value='record.name' />
            </v-col>
            <v-col cols='6'>
              <component :is='editing ? "v-text-field" : "TitleValue"' v-model='record.cargo' label='Cargo' custom-label='Cargo' :value='record.cargo' />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols='6'>
              <component
                :is='editing ? "v-text-field" : "TitleValue"'
                v-model='record.filiacaoMae'
                label='Nome da mãe'
                custom-label='Nome da mãe'
                :value='record.filiacaoMae'
                @keypress='preventNumberInput'
              />
            </v-col>
            <v-col cols='6'>
              <component
                :is='editing ? "v-text-field" : "TitleValue"'
                v-model='record.filiacaoPai'
                label='Nome do pai'
                custom-label='Nome do pai'
                :value='record.filiacaoPai'
                @keypress='preventNumberInput'
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols='6'>
              <component
                :is='editing ? "v-text-field" : "TitleValue"'
                v-model='record.cpf'
                label='CPF'
                custom-label='CPF'
                :value='record.cpf'
                hint='Somente números'
                maxlength='11'
              />
            </v-col>
            <v-col cols='6'>
              <component
                :is='editing ? "v-text-field" : "TitleValue"'
                v-model='record.validade'
                label='Validade da carteira'
                custom-label='Validade da carteira'
                :value='record.validade'
                type='date'
                @input='noExpirationDate = record.validade === ""'
              />
              <v-checkbox
                v-model='noExpirationDate'
                label='Validade não determinada'
                hide-details
                :disabled='!editing'
                @change='setExpirationDate'
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols='6'>
              <component
                :is='editing ? "v-text-field" : "TitleValue"'
                v-model='record.matricula'
                label='Matrícula'
                custom-label='Matrícula'
                :value='record.matricula'
                maxlength='9'
              />
            </v-col>
            <v-col cols='6'>
              <component
                :is='editing ? "v-autocomplete" : "TitleValue"'
                v-model='record.tipoS'
                label='Tipo Sanguíneo'
                custom-label='Tipo Sanguíneo'
                :value='record.tipoS'
                :items='rhFactors'
                item-value='id'
                item-text='name'
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols='6'>
              <component
                :is='editing ? "v-autocomplete" : "TitleValue"'
                v-model='record.infoPorte'
                label='Informação sobre porte de arma'
                custom-label='Informação sobre porte de arma'
                :value='record.infoPorte'
                :items='gunPossessionInfo'
                :options='{ tooltip: infoPorteTooltip(record.infoPorte) }'
                item-value='id'
                item-text='description'
                @change='isRetired ? record.reservaReforma = "" : ""'
              />
            </v-col>
            <v-col cols='6'>
              <component
                :is='editing ? "v-text-field" : "TitleValue"'
                v-model='record["número"]'
                label='Número'
                custom-label='Número'
                :value='record["número"]'
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols='6'>
              <component
                :is='editing ? "v-text-field" : "TitleValue"'
                v-model='record.data_nascimento'
                label='Data de nascimento'
                custom-label='Data de nascimento'
                :value='record.data_nascimento'
                type='date'
              />
            </v-col>
            <v-col cols='6'>
              <component
                :is='editing ? "v-select" : "TitleValue"'
                v-model='record.gender'
                label='Sexo'
                custom-label='Sexo'
                :value='record.gender'
                hint='Preencha apenas com a primeira letra: M, F ou U (prefere não identificar)'
                :items='genders'
                item-value='id'
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols='6'>
              <component :is='editing ? "v-text-field" : "TitleValue"' v-model='record.nacionalidade' label='Nacionalidade' custom-label='Nacionalidade' :value='record.nacionalidade' />
            </v-col>
            <v-col cols='6'>
              <component
                :is='editing ? "v-text-field" : "TitleValue"'
                v-model='record.naturalidade'
                label='Naturalidade'
                custom-label='Naturalidade'
                :value='record.naturalidade'
                hint='Cidade / Estado'
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols='6'>
              <component
                :is='editing ? "v-text-field" : "TitleValue"'
                v-model='record.rg_uf'
                label='RG / UF'
                custom-label='RG / UF'
                :value='record.rg_uf'
                hint='RG somente numeros / Sigla do estado'
              />
            </v-col>
            <v-col v-if='isPMorCBM && isRetired' cols='6'>
              <component
                :is='editing ? "v-select" : "TitleValue"'
                v-model='record.reservaReforma'
                label='Reserva / Reformado'
                custom-label='Reserva / Reformado'
                :value='record.reservaReforma'
                :items='retiredInfoList'
                item-value='id'
                clearable
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols='6'>
              <component :is='editing ? "v-text-field" : "TitleValue"' v-model='record.nivel_classe' label='Nível / Classe' custom-label='Nível / Classe' :value='record.nivel_classe' />
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if='!validation && !editing' cols='6'>
              <TitleValue custom-label='MRZ' :value='record.mrz' />
            </v-col>
            <v-col cols='6' style='position: relative'>
              <TitleValue custom-label='Assinatura' :value='record.assinatura_IMG' :options='{ type: "image", width: 300 }' />
              <v-btn
                v-if='editing'
                x-small
                class='ml-9 img-upload-btn'
                fab
                style='bottom: 20px; left: 280px; position: absolute;'
                @click='openFileBrowser("signature")'
              >
                <v-icon> mdi-upload </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </component>

    <v-alert v-if='notFound' type='warning'>
      CPF não encontrado
    </v-alert>

    <input id='fileInput' type='file' style='display: none' @change='onFileUpload($event)'>

    <v-dialog v-if='confirmEditDialog' v-model='confirmEditDialog' max-width='30%' persistent style='z-index: 999999'>
      <v-container class='pa-0'>
        <v-card class='pa-3'>
          <v-row class='pa-3'>
            <v-col v-for='editedItem in editDiff' :key='editedItem.field' class='pa-3' cols='6' style='line-height: 21px;'>
              <div v-if='editedItem.field === "foto"'>
                <v-row class='pa-3 justify-space-around'>
                  <TitleValue custom-label='Foto' :value='editedItem.newValue' :options='{ type: "image", width: 100 }' />
                  <TitleValue custom-label='Foto anterior' :value='editedItem.oldValue' :options='{ type: "image", width: 100, class: "text-muted" }' />
                </v-row>
              </div>
              <div v-else-if='editedItem.field === "assinatura_IMG"'>
                <TitleValue custom-label='Assinatura' :value='editedItem.newValue' :options='{ type: "image", width: 300 }' />
                <TitleValue class='mt-2' custom-label='Assinatura anterior' :value='editedItem.oldValue' :options='{ type: "image", width: 300, class: "text-muted" }' />
              </div>
              <div v-else>
                <TitleValue :custom-label='labelDictionary[editedItem.field].label || editedItem.field' :value='editedItem.newValue' />
                <span class='text-muted'> (Valor anterior: {{ editedItem.oldValue }})</span>
              </div>
            </v-col>
          </v-row>
          <v-row class='ma-3 justify-end'>
            <v-btn class='mr-2' text color='secondary' @click='confirmEditDialog = false'>
              {{ $t('CANCEL') }}
            </v-btn>
            <v-btn color='secondary' @click='confirmEdit'>
              {{ $t('CONFIRM') }}
            </v-btn>
          </v-row>
        </v-card>
      </v-container>
    </v-dialog>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import Swal from 'sweetalert2';
  import { format } from 'date-fns';
  import { mapGetters } from 'vuex';
  import { ACCOUNT_ROLE } from '@/store/modules/Auth';
  import { objectDiff } from '../../util/object';
  import { fileToBase64 } from '../../util/file';

  export default {
    name: 'RecordSearch',
    components: {
      TitleValue: () => import('../../components/TitleValue/TitleValue'),
    },
    props: {
      validation: {
        type: Boolean,
        default: false,
      },
      customerId: {
        type: Number,
        default: 0,
      },
      validationCpf: {
        type: String,
        default: '',
      },
    },
    computed: {
      ...mapGetters('auth', [
        'getUser',
      ]),
      isPMorCBM: function () {
        if (!this.customerId) {
          return true;
        }
        const customer = this.customerList.find((c) => c.id === this.customerSelect.id);
        return customer.name.toUpperCase().indexOf('BOMBEIRO') >= 0 || customer.name.toUpperCase().indexOf('MILITAR') >= 0;
      },
      isRetired: function () {
        return this.record.infoPorte === 'APCP' || this.record.infoPorte === 'APSP';
      },
      isSuperAdmin: function () {
        return this.getUser.account.accountRoleId === ACCOUNT_ROLE.SUPER_ADMIN;
      },
    },
    data: function () {
      return {
        customerSelect: '',
        customerList: [],
        importType: undefined,
        cpf: '',
        record: undefined,
        notFound: false,
        imagePngHeader: 'data:image/png;base64, ',
        editing: false,
        recordOriginalInfo: {
          name: '',
        },
        editDiff: {},
        confirmEditDialog: false,
        fileUploadType: '',
        labelDictionary: {
          foto: { label: 'Foto', order: 1 },
          assinatura_IMG: { label: 'Assinatura', order: 2 },
          qrCode: { order: 3 },
          name: { label: 'Nome completo', order: 4 },
          filiacaoMae: { label: 'Nome da mãe', order: 5 },
          filiacaoPai: { label: 'Nome do pai', order: 6 },
          cargo: { label: 'Cargo', order: 7 },
          cpf: { label: 'CPF', order: 8 },
          validade: { label: 'Validade', order: 9 },
          matricula: { label: 'Matrícula', order: 10 },
          tipoS: { label: 'Tipo sanguíneo', order: 11 },
          infoPorte: { label: 'Porte de arma', order: 12 },
          'número': { label: 'Número', order: 13 }, // eslint-disable-line
          data_nascimento: { label: 'Data de nascimento', order: 14 },
          gender: { label: 'Sexo', order: 15 },
          nacionalidade: { label: 'Nacionalidade', order: 16 },
          naturalidade: { label: 'Naturalidade', order: 17 },
          rg_uf: { label: 'RG / UF', order: 18 },
          reservaReforma: { label: 'Reserva / Reformado', order: 19 },
          nivel_classe: { label: 'Nível / Classe', order: 20 },
          mrz: { order: 21 },
          openbioValidation: { order: 22 },
          id: { order: 23 },
        },
        rhFactors: [
          'A-', 'A+',
          'B-', 'B+',
          'AB-', 'AB+',
          'O-', 'O+',
        ],
        gunPossessionInfo: [
          { id: 'ATSP', description: this.$t('ACTIVE_WITHOUT_POSSESSION') },
          { id: 'ATCP', description: this.$t('ACTIVE_WITH_POSSESSION') },
          { id: 'APSP', description: this.$t('RETIRED_WITHOUT_POSSESSION') },
          { id: 'APCP', description: this.$t('RETIRED_WITH_POSSESSION') },
        ],
        genders: [
          { id: 'O', text: this.$t('OTHER') },
          { id: 'M', text: this.$t('MALE') },
          { id: 'F', text: this.$t('FEMALE') },
        ],
        retiredInfoList: ['Reserva', 'Reformado'],
        expirationDateAux: undefined,
        noExpirationDate: false,
        validationsStatuses: {
          PENDING: 1,
          ACCEPTED: 2,
          REJECTED: 3,
        },
        validationStatusId: undefined,
      };
    },
    mounted: function () {
      this.getCustomers();
      if (this.validationCpf) {
        this.cpf = this.validationCpf;
      }
      if (this.customerId) {
        this.customerSelect = { id: this.customerId };
      }
      if (this.validation) {
        this.search();
      }
    },
    methods: {
      getCustomers: async function () {
        try {
          this.$store.dispatch('loading/toggleLoading');
          const result = await axios({
            url: '/customers',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
          });
          this.customerList = result.data.customers;
          this.$store.dispatch('loading/toggleLoading');
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
          this.$store.dispatch('loading/toggleLoading');
        }
      },
      search: async function () {
        if (!this.customerSelect) {
          Swal.fire({
            icon: 'warning',
            text: 'É necessário informar um cliente',
            confirmButtonText: 'OK',
            confirmButtonColor: this.$vuetify.theme.defaults.light.secondary,
          });
          return;
        }

        this.$store.dispatch('loading/toggleLoading');
        try {
          const result = await axios({
            url: '/document/data',
            params: {
              customer: this.customerSelect.id,
              filter: {
                columnName: 'cpf',
                value: this.cpf,
              },
            },
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
          });
          this.record = result.data.uploadPeople && result.data.uploadPeople[0];
          this.validationStatusId = this.record.validation_status_id;
          const sortedLabels = Object.entries(this.labelDictionary).sort(([, a], [, b]) => a.order - b.order);
          this.record = Object.fromEntries(sortedLabels.map(([key]) => [key, this.record[key]]));

          if (!this.record.validade) {
            this.noExpirationDate = true;
          }

          this.notFound = !this.record;
          this.$store.dispatch('loading/toggleLoading');
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
          this.$store.dispatch('loading/toggleLoading');
        }
      },
      infoPorteTooltip: function (infoPorte) {
        if (!infoPorte) {
          return '';
        }
        switch (infoPorte) {
          case 'ATCP': return 'Ativo com porte';
          case 'ATSP': return 'Ativo sem porte';
          case 'APCP': return 'Aposentado/Reserva com porte';
          case 'APSP': return 'Aposentado/Reserva sem porte';
          default: return '';
        }
      },
      toggleEditMode: function () {
        this.editing = !this.editing;
      },
      editRecord: function () {
        this.toggleEditMode();
        this.recordOriginalInfo = Object.assign({}, this.record); // eslint-disable-line

        const birthDate = this.record.data_nascimento.split('/');
        const expirationDate = this.record.validade ? this.record.validade.split('/') : undefined;
        this.record.data_nascimento = format(new Date(birthDate[2], birthDate[1] - 1, birthDate[0]), 'yyyy-MM-dd');

        if (expirationDate) {
          this.record.validade = format(new Date(expirationDate[2], expirationDate[1] - 1, expirationDate[0]), 'yyyy-MM-dd');
        }
      },
      confirmationPopup: function () {
        this.editDiff = objectDiff(this.recordOriginalInfo, this.record);
        for (let i = 0; i < this.editDiff.length; i++) {
          if (this.editDiff[i].field === 'data_nascimento' || this.editDiff[i].field === 'validade') {
            const date = this.editDiff[i].newValue ? this.editDiff[i].newValue.split('-') : undefined;
            this.editDiff[i].newValue = date ? format(new Date(date[0], date[1] - 1, date[2]), 'dd/MM/yyyy') : undefined;

            if (this.editDiff[i].newValue === this.editDiff[i].oldValue) {
              this.editDiff.splice(i, 1);
              i--;
            }
          }
        }
        if (!this.editDiff.length) {
          return Swal.fire({
            icon: 'info',
            title: 'Cadastro não alterado',
            text: 'Não houve alteração no cadastro.',
            showCloseButton: true,
            confirmButtonColor: this.$vuetify.theme.defaults.light.secondary,
            confirmButtonText: 'OK',
          });
        }
        this.confirmEditDialog = true;
      },
      confirmEdit: async function () {
        if (!this.record.filiacaoPai && !this.record.filiacaoMae) {
          Swal.fire({
            icon: 'error',
            title: 'Campos obrigatórios',
            text: 'Pelo menos um dos nomes da mãe ou do pai deve estar preenchido.',
            confirmButtonText: 'OK',
          });
          return;
        }
        await axios({
          url: '/record',
          data: {
            recordId: this.record.id,
            customerId: this.customerSelect.id,
            values: this.editDiff,
          },
          baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
          method: 'PUT',
          responseType: 'json',
          withCredentials: true,
        });
        this.confirmEditDialog = false;
        this.toggleEditMode();
        this.cpf = this.record.cpf;
        this.search();
      },
      cancelEdit: function () {
        Swal.fire({
          icon: 'warning',
          title: 'Cancelar edição',
          text: 'Deseja cancelar a edição deste cadastro?',
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonColor: this.$vuetify.theme.defaults.light.secondary,
          confirmButtonText: this.$t('CONFIRM'),
          cancelButtonText: this.$t('CANCEL'),
          reverseButtons: true,
        }).then(async (result) => {
          if (result.value) {
            this.record = Object.assign({}, this.recordOriginalInfo); // eslint-disable-line
            this.toggleEditMode();
            this.$forceUpdate();
          }
        });
      },
      validateRecord: function (validation) {
        // eslint-disable-next-line no-nested-ternary
        const titleMessage = validation === this.validationsStatuses.PENDING ? 'pendência' : validation === this.validationsStatuses.ACCEPTED ? 'aceitação' : 'rejeição';
        // eslint-disable-next-line no-nested-ternary
        const textMessage = validation === this.validationsStatuses.PENDING ? 'pendência' : validation === this.validationsStatuses.ACCEPTED ? 'aceitação' : 'rejeição';
        const extraMessage = `${validation === this.validationsStatuses.ACCEPTED ? 'Verifique se TODOS os dados estão corretos. Dados incorretos irão gerar carteiras erradas. Se necessário, cancele esta janela e verifique novamente.' : ''}`;

        Swal.fire({
          icon: 'warning',
          title: `Confirmação de ${ titleMessage }`,
          text: `Deseja confirmar a ${ textMessage } deste cadastro? ${ extraMessage }`,
          allowEscapeKey: false,
          allowOutsideClick: false,
          showCancelButton: true,
          confirmButtonColor: this.$vuetify.theme.defaults.light.secondary,
          confirmButtonText: this.$t('CONFIRM'),
          cancelButtonText: this.$t('CANCEL'),
          reverseButtons: true,
        }).then(async (result) => {
          if (result.value) {
            await axios({
              url: '/record/validation',
              data: {
                recordId: this.record.id,
                customerId: this.customerSelect.id,
                validationId: validation,
              },
              baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
              method: 'PUT',
              responseType: 'json',
              withCredentials: true,
            });
            this.$emit('reload-data', { cpf: this.record.cpf });
          }
        });
      },
      openFileBrowser: function (type) {
        this.fileUploadType = type;
        document.getElementById('fileInput').click();
      },
      onFileUpload: async function (event) {
        if (event.target.files.length > 0) {
          this.uploadedBase64 = await fileToBase64(event.target.files[0]);
          if (this.fileUploadType === 'signature') {
            this.record.assinatura_IMG = this.uploadedBase64;
          } else {
            this.record.foto = this.uploadedBase64;
          }
          this.$forceUpdate();
        }
        event.target.files = undefined;
        document.getElementById('fileInput').value = '';
      },
      setExpirationDate: function () {
        if (this.noExpirationDate) {
          this.expirationDateAux = this.record.validade;
        }
        this.record.validade = this.noExpirationDate ? undefined : this.expirationDateAux;
      },
      canChangeStatusBack: function () {
        return this.isSuperAdmin && [this.validationsStatuses.ACCEPTED, this.validationsStatuses.REJECTED].includes(this.validationStatusId);
      },
      preventNumberInput (event) {
        const keyCode = event.keyCode ? event.keyCode : event.which;
        if (keyCode >= 48 && keyCode <= 57) {
          event.preventDefault();
        }
      },
    },
  };
</script>

<style lang='scss' scoped>
  $speed: "0.3s";
  $transition: all #{$speed} cubic-bezier(0.310, -0.105, 0.430, 1.400);

  /* Main Styles */
  .animated-btn {
    display: block;
    width: 36px;
    height: 36px;
    line-height: 36px;
    margin: auto;
    color: #fff;
    cursor: pointer;
    overflow: hidden;
    border-radius: 5px;
    transition: $transition;

    span,
    .icon {
      display: block;
      height: 100%;
      text-align: center;
    }

    span {
      width: 0%;
      opacity: 0;
      line-height: inherit;
      text-transform: uppercase;
      transition: $transition;
    }

    &:hover {
      width: auto;

      span {
        margin: 0px 8px;
        width: 72%;
        opacity: 1;
      }

      .icon {
        width: 28%;
      }
    }
  }

  .img-upload-btn {
    color: var(--v-secondary-base);
    background-color: white;
    bottom: 16px;
    left: 141px;
    position: absolute;
  }
</style>
